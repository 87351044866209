export default function () {
    const form = document.querySelector('.form-onboarding')
    if (form) {
        // Fonction pour mettre à jour le label de structure
        const updateStructureLabel = () => {
            const selectedStructure = document.querySelector('input[name="structure"]:checked')
            const structureButton = document.querySelector(
                '.dropdown-onboarding[data-name="structure"] .dropdown-toggle-label-name'
            )
            if (selectedStructure && structureButton) {
                const structureLabel = selectedStructure.parentElement
                const structureName = structureLabel ? structureLabel.querySelector('.name')?.textContent : ''
                if (structureName) {
                    structureButton.innerHTML = structureName
                } else {
                    console.error('Impossible de trouver le nom de la structure sélectionnée')
                }
            }
        }

        // Fonction pour mettre à jour le label des intérêts
        const updateInterestLabel = () => {
            const checkedInterests = document.querySelectorAll('input[name="interest[]"]:checked')
            const interestButton = document.querySelector(
                '.dropdown-onboarding[data-name="interest"] .dropdown-toggle-label-name'
            )
            if (interestButton) {
                if (checkedInterests.length > 0) {
                    const interestLabels = Array.from(checkedInterests).map(
                        input => input.nextElementSibling.textContent
                    )
                    interestButton.innerHTML = `${interestLabels.join(', ')}`
                } else {
                    interestButton.innerHTML = interestButton.dataset.defaultname
                }
            }
        }

        // Ajouter des écouteurs d'événements pour les changements de structure
        const structureInputs = form.querySelectorAll('input[name="structure"]')
        structureInputs.forEach(input => {
            input.addEventListener('change', updateStructureLabel)
        })

        // Ajouter des écouteurs d'événements pour les changements d'intérêts
        const interestInputs = form.querySelectorAll('input[name="interest[]"]')
        interestInputs.forEach(input => {
            input.addEventListener('change', updateInterestLabel)
        })

        form.addEventListener('submit', event => {
            let hasStructureChecked = document.querySelector('input[name="structure"]:checked')
            let hasInterestChecked = document.querySelectorAll('input[type="checkbox"]:checked').length > 0

            // Suppression des anciens messages d'erreur
            let errorDiv = document.querySelector('.form-error')
            if (errorDiv) {
                errorDiv.remove()
            }

            let errorMessages = []

            // Vérification du champ 'structure'
            if (!hasStructureChecked) {
                errorMessages.push('Veuillez sélectionner une structure.')
            }

            // Vérification des checkbox 'interest'
            if (!hasInterestChecked) {
                errorMessages.push("Veuillez sélectionner au moins un centre d'intérêt.")
            }

            // Afficher les erreurs et empêcher la soumission du formulaire
            if (errorMessages.length > 0) {
                event.preventDefault()

                // Création de la div pour afficher les erreurs
                let errorDiv = document.createElement('div')
                errorDiv.classList.add('form-error')
                errorMessages.forEach(message => {
                    let errorMessage = document.createElement('p')
                    errorMessage.textContent = message
                    errorDiv.appendChild(errorMessage)
                })

                // Ajout de la div d'erreur sous le bouton de soumission
                form.querySelector('.btn-submit').insertAdjacentElement('beforebegin', errorDiv)
            }
        })
    }
}
