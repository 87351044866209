export default function () {
    const tmlLogin = document.querySelector('.tml-login')
    if (!tmlLogin) return

    // Récupérer les éléments
    const radioAdherent = document.getElementById('space_adherent_space')
    const radioPartner = document.getElementById('space_partner_space')
    const text1Wrap = document.querySelector('.tml-text1-wrap')
    const text2Wrap = document.querySelector('.tml-text2-wrap')

    // Fonction pour mettre à jour l'affichage des blocs texte
    function updateTextDisplay() {
        if (radioAdherent.checked) {
            text1Wrap.style.display = 'block'
            text2Wrap.style.display = 'none'
        } else if (radioPartner.checked) {
            text1Wrap.style.display = 'none'
            text2Wrap.style.display = 'block'
        }
    }

    // Initialisation : mettre à jour l'affichage en fonction du bouton radio sélectionné
    updateTextDisplay()

    // Écouter les changements sur les boutons radio
    radioAdherent.addEventListener('change', updateTextDisplay)
    radioPartner.addEventListener('change', updateTextDisplay)

    // Ajouter un bouton pour afficher/masquer le mot de passe
    const pwdWrap = document.querySelector('.tml-pwd-wrap')
    if (pwdWrap) {
        const pwdInput = document.getElementById('user_pass')

        // Créer le bouton
        const toggleBtn = document.createElement('button')
        toggleBtn.type = 'button'
        toggleBtn.textContent = 'Show'
        toggleBtn.className = 'toggle-password'

        // Ajouter le bouton après l'input password
        pwdWrap.appendChild(toggleBtn)

        // Fonction pour basculer la visibilité du mot de passe
        toggleBtn.addEventListener('click', function () {
            if (pwdInput.type === 'password') {
                pwdInput.type = 'text'
                toggleBtn.textContent = 'Hide'
                toggleBtn.classList.add('show')
            } else {
                pwdInput.type = 'password'
                toggleBtn.textContent = 'Show'
                toggleBtn.classList.remove('show')
            }
        })
    }
}
