import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const textMedias = document.querySelectorAll('.js-text-media-block')

    if (textMedias.length) {
        textMedias.forEach(textMedia => {
            const textMediasSlider = new Swiper(`#${textMedia.id} .text-media-desktop .swiper`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 32,
                navigation: {
                    nextEl: `#${textMedia.id} .text-media-desktop .swiper-button-next`,
                    prevEl: `#${textMedia.id} .text-media-desktop .swiper-button-prev`
                },
                pagination: {
                    el: `#${textMedia.id} .text-media-desktop .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination],
                on: {
                    init: function (swiper) {
                        addShadowHandler(swiper)
                    },
                    slideChange: function (swiper) {
                        addShadowHandler(swiper)
                    }
                }
            })

            const textMediasMobileSlider = new Swiper(`#${textMedia.id} .text-media-mobile .swiper`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 16,
                navigation: {
                    nextEl: `#${textMedia.id} .text-media-mobile .swiper-button-next`,
                    prevEl: `#${textMedia.id} .text-media-mobile .swiper-button-prev`
                },
                pagination: {
                    el: `#${textMedia.id} .text-media-mobile .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination],
                on: {
                    init: function (swiper) {
                        addShadowHandler(swiper)
                    },
                    slideChange: function (swiper) {
                        addShadowHandler(swiper)
                    }
                }
            })
        })
    }
}

function addShadowHandler(swiper) {
    if (swiper.slides.length) {
        if (swiper.slides[swiper.activeIndex].hasAttribute('data-shadow')) {
            swiper.el.classList.add('has-shadow')
        } else {
            swiper.el.classList.remove('has-shadow')
        }
    }
}
