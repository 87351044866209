export default initAnimation => {
    animateIncrementation()
}

function animateIncrementation() {
    const numbers = document.querySelectorAll('[data-number="increment"]')
    if (numbers.length) {
        const observer = new IntersectionObserver(handleIntersect, { threshold: 0.1 })

        numbers.forEach(target => {
            observer.observe(target)
        })
    }
}

function handleIntersect(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const target = entry.target
            const numValue = parseInt(target.innerText)
            let start = 0
            let step

            switch (target.innerText.length) {
                case 1:
                    step = 1
                    break
                case 2:
                    step = 10
                    break
                case 3:
                    step = 50
                    break
                default:
                    step = 500
            }

            setInterval(() => {
                if (start < numValue) {
                    start += step
                    target.innerText = start
                } else {
                    target.innerText = numValue
                }
            }, 75)
            observer.unobserve(target)
        }
    })
}
