import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const temoignanges = document.querySelectorAll('.js-temoignages-block')

    if (temoignanges.length) {
        temoignanges.forEach(temoignange => {
            const temoignagesItems = temoignange.querySelectorAll('.quote-item')
            const temoignageVisuelSlider = new Swiper(`#${temoignange.id} .temoignages-desktop .swiper`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 32,
                navigation: {
                    nextEl: `#${temoignange.id} .temoignages-desktop .swiper-button-next`,
                    prevEl: `#${temoignange.id} .temoignages-desktop .swiper-button-prev`
                },
                pagination: {
                    el: `#${temoignange.id} .temoignages-desktop .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })

            const temoignagesMobileSlider = new Swiper(`#${temoignange.id} .temoignages-mobile .swiper`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 32,
                navigation: {
                    nextEl: `#${temoignange.id} .temoignages-mobile .swiper-button-next`,
                    prevEl: `#${temoignange.id} .temoignages-mobile .swiper-button-prev`
                },
                pagination: {
                    el: `#${temoignange.id} .temoignages-mobile .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })

            temoignageVisuelSlider.on('slideChange', () => {
                temoignagesItems.forEach((element, id) => {
                    element.classList.remove('active')
                })
                temoignagesItems[temoignageVisuelSlider.activeIndex].classList.add('active')
            })
        })
    }
}
