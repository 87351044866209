export function Menu() {
    const $menu = document.querySelectorAll('.dropdown.menu')

    $menu.forEach($dropdownMenu => {
        $dropdownMenu.querySelectorAll('.dropdown-item').forEach($dropdownItem => {
            $dropdownItem.addEventListener('click', () => {
                $dropdownMenu.querySelectorAll('.dropdown-item').forEach($dropdownItemTemp => {
                    $dropdownItemTemp.classList.remove('selected')
                })
                $dropdownItem.classList.add('selected')
                $dropdownMenu.querySelector('.dropdown-toggle').innerHTML = $dropdownItem.innerHTML
                $dropdownMenu.setAttribute('data-value', $dropdownItem.getAttribute('name'))
                $dropdownMenu.value = $dropdownItem.getAttribute('name')
                $dropdownMenu.dispatchEvent(new Event('change'))
            })
        })
    })
}
