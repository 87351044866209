export default function () {
    const formBlocks = document.querySelectorAll('.gform_wrapper')

    if (formBlocks.length) {
        formBlocks.forEach(form => {
            const formSubmit = form.querySelector('button')
            const consentContainer = form.querySelector('.ginput_container_consent')
            if (formSubmit) {
                const arrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 16">
    <path fill="currentColor" d="m8.5 13.333-.95-.933 3.734-3.733H3.167V7.333h8.117L7.55 3.6l.95-.933L13.834 8 8.5 13.333Z"/>
</svg>`
                const checkmarkSpan = document.createElement('span')
                checkmarkSpan.className = 'checkmark'

                formSubmit.innerHTML += arrowIcon
                if (consentContainer) {
                    const consentCheckBox = consentContainer.querySelector('input[type=checkbox]')
                    consentContainer.insertBefore(checkmarkSpan, consentCheckBox.nextSibling)
                }
            }
        })
    }
}
