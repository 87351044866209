/* global events */
/* eslint-disable prettier/prettier */
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import { getDatesInRange, getTheMonthNumber, getSelectedValues } from './helpers'
import { addFilter } from './filters'
import { enableApplyFilters, enableResetFilters } from './dropdown'
import { runFilters } from './helpers'
import { updateFavorisId } from '../components/favoris'

const date = new Date();
const year = date.getFullYear();
let month = year + '-' + (date.getMonth() + 1).toString().padStart(2, '0');
let $currentApplyFilter = null

export default function ($action) {
    const $calendar = document.getElementById('calendar')
    let calendar = null
    if(!$calendar) return
    let eventDays = []
    const $monthSelector = document.querySelector('.calendrier-menu')
    const applyFilters = document.querySelectorAll('.list-filters .apply-filters')
    const resetFilters = document.querySelectorAll('.list-filters .reset-filters')
    $monthSelector.value = month
    $currentApplyFilter = document.querySelector('.calendrier .list-filters .apply-filters')

    addFilter('getSelectedValues', function(selectedValues) {
        return {
            ...selectedValues,
            month,
            action: $action,
            postType: 'evenement'
        }
    })

    if (!$calendar) return

    ;($ => {
        $(document).ready(function() {
            const targetNode = $('#calendar')[0];

            if (targetNode) {
                // Create a new MutationObserver instance
                const observer = new MutationObserver(function(mutationsList) {
                    mutationsList.forEach(function(mutation) {
                        if (mutation.type === 'childList') {
                            $('#calendar tr').each(function() {
                                const allOtherDays = $(this).find('td').length > 0 && $(this).find('td').get().every(function(td) {
                                    return $(td).hasClass('calendar__day--other');
                                });

                                if (allOtherDays) {
                                    $(this).addClass('d-none');
                                }
                            });
                        }
                    });
                });
                observer.observe(targetNode, { childList: true, subtree: true });
            }
        });
    })(window.jQuery)

    ;($ => {
        calendar = new Calendar($calendar, {
            plugins: [ dayGridPlugin ],
            locale: frLocale,
            initialView: 'dayGridMonth',
            height: 'auto',
            headerToolbar: {

                left: 'prev,next today',
                center: 'title',
                right: '',
            },
            events: {
                url: ajaxurl.url,
                method: 'POST',
                extraParams: function () {
                    // const $applyFilters = document.querySelector('.calendrier .list-filters .apply-filters')
                    const selectedValues = getSelectedValues($currentApplyFilter)
                    return selectedValues
                },
                failure: function () {
                    console.log('error')
                }
            },

            eventSourceSuccess: function (events) {
                updateCalendarView(events['events'])
                addAllEvents(events['eventsHtml'])
                // console.log('eventSourceSuccess')
                return events['events']
            },
            eventDisplay: 'background',
            dayCellClassNames: function (info) {
                if(info.isOther) return 'calendar__day--other'
            },
            dayCellContent: function (info) {
                const dayNumber = parseInt(info.dayNumberText, 10)
                let displayNumber = dayNumber < 10 ? '0' + dayNumber : dayNumber
                return displayNumber
            },
        })

        calendar.render()

        applyFilters.forEach($applyFilter => {
            $applyFilter.addEventListener('data-send', (event) => {
                $currentApplyFilter = $applyFilter
                // console.log('data-send')
                document.querySelectorAll('.calendar__day--event').forEach($day => {
                    $day.classList.remove('calendar__day--event')
                    $day.querySelector('.fc-daygrid-day-number').removeEventListener('click', handleEventClick)
                    // console.log($day.classList.toString())
                })
                console.log('refetchEvents')
                calendar.refetchEvents()
                closeCalendarOffCanvas()
            })
        })

        $('.btn-view-toggle').click(() => {
            $('.calendrier').toggleClass('view-list')
            calendar.render()
        })

        $('.btn-filter-show-calendrier').click(() => {
            document.querySelector('.calendrier-offcanvas .list-filters-offcanvas-button .apply-filters').dispatchEvent(new Event('click'))
        })

        $('.btn-filter-show-comptes-rendus').click(() => {
            document.querySelector('.comptes-rendus-offcanvas .list-filters-offcanvas-button .apply-filters').dispatchEvent(new Event('click'))
        })
    })(window.jQuery)

    $monthSelector.addEventListener('change', event => {
        const $applyFilters = window.innerWidth > 991 ? document.querySelector('.calendrier .list-filters .apply-filters') : document.querySelector('.calendrier ~ .offcanvas .list-filters .apply-filters')
        month = event.target.value
        const date = new Date(month + '-01');
        enableApplyFilters()
        enableResetFilters()
        if($applyFilters) {
            runFilters($applyFilters)
        }

        console.log(date)
        calendar.gotoDate(date)
        calendar.refetchEvents()
        // console.log(calendar)
    })

    applyFilters.forEach($applyFilter => {
        $applyFilter.addEventListener('data-load', (event) => {
            enableApplyFilters()
        })
    })

    resetFilters.forEach($resetFilter => {
        $resetFilter.addEventListener('data-reset', event => {

        })
    })
}

function addEvent(event) {
    ;(($) => {
        const currentUrl = window.location.href
        const $eventsParent = $('.calendrier-event-body')
        const $eventCopy = $('.event-adherent.d-none').clone()
        let eventPermalink = event.permalink
        updateFavorisId($eventCopy, event.eventId)
        // console.log(event)

        if(!$eventCopy || !$eventsParent) return
        $eventCopy.removeClass('d-none')
        $eventCopy.attr('id', `event-adherent-${event.eventId}`)
        $eventCopy.find('.favoris-btn')?.attr('data-post', event.eventId)
        updateElementContent($eventCopy, 'event-adherent-title', event.title)
        updateElementContent($eventCopy, 'event-adherent-date', event.dateHtml)
        updateElementContent($eventCopy, 'event-adherent-hours', event.timeHtml)
        updateElementContent($eventCopy, 'event-adherent-location', event.location)
        updateElementContent($eventCopy, 'event-adherent-type', event.event_type)
        updateElementContent($eventCopy, 'event-adherent-more',event.btnEventSubscribe)
        if(currentUrl.includes('espace-connecte') && !event.permalink.includes('espace-connecte')) {
            const eventUrl = new URL(eventPermalink)
            eventPermalink = `${eventUrl.origin}/espace-connecte${eventUrl.pathname}`
        }
        $eventCopy.find('.event-adherent-link a').attr('href', eventPermalink)
        if(event.reserved) {
            $eventCopy.find('.event-adherent-public').remove()
        } else {
            updateElementContent($eventCopy, 'event-adherent-public', 'Public')
        }
        if(event.eventSubscribeStatus) {
            $eventCopy.find('.event-adherent-time').
            append('<div class="badge p-1 ms-0 rounded-1 text-bg-primary event-adherent-subscribed">Inscrit</div>')
        }
        $eventsParent.append($eventCopy)
        const days = getDatesInRange(event.start, event.end, getTheMonthNumber(month))
        days.forEach(day => {
            $eventCopy.addClass('event-' + month + '-' + day.toString().padStart(2, '0'))
        })
    })(window.jQuery)
}

function updateElementContent($parent, elementClass, content) {
    const $element = $parent.find('.' + elementClass)
    if(content && content !== '') {
        $element.html(content)
    } else {
        $element.addClass('d-none')
    }
}

function updateCalendarView (events) {
    events.forEach(event => {
        const days = getDatesInRange(event.start, event.end, getTheMonthNumber(month))
        days.forEach(day => {
            document.querySelectorAll('[data-date="' + month + '-' + day.toString().padStart(2, '0') + '"]').forEach($day => {
                $day.classList.add('calendar__day--event')
                $day.querySelector('.fc-daygrid-day-number').addEventListener('click', handleEventClick)
            })
        })
    })
}

function removePreviousEvents () {
    document.querySelectorAll('.card.event-adherent').forEach($event => {
        if(!$event.classList.contains('d-none')) $event.remove()
    })
}

function closeCalendarOffCanvas () {
    const $closeOffcanvasButton = document.querySelector('.offcanvas .offcanvas-header button')
    if($closeOffcanvasButton) $closeOffcanvasButton.dispatchEvent(new Event('click'))
}

function addAllEvents (events) {
    removePreviousEvents()
    events.forEach(addEvent)
}

function handleEventClick (event) {
    ;(($) => {
        const $day = event.target.closest('.fc-daygrid-day')
        if($day) {
            const date = $day.getAttribute('data-date')
            const top = document.querySelector('.event-' + date).offsetTop - 20
            $('.calendrier-event').animate({ scrollTop: top }, 600)
        }
    })(window.jQuery)
}
