export function applicationForm() {
    const formWrapper = document.querySelector('#apply-form')
    const accordionForm = formWrapper?.querySelector('.accordion-form')

    if (formWrapper) {
        ;($ => {
            const $formWrapper = $('#apply-form')
            const $addTitleInput = $('#apply-form .field-offre-title input')
            const offreId = $formWrapper.data('offre-id')
            // console.log(offreId, $addTitleInput)
            // $addTitleInput.val('<a href="' + window.location.href + '">' + $formWrapper.data('offre-title') + '</a>')
            $addTitleInput.val($formWrapper.data('offre-title'))

            const $confirmMessage = $('.gform_confirmation_message')
            const $errorValidation = $('.gform_validation_error')

            // console.log('$confirmMessage', $confirmMessage)
            if ($confirmMessage.length || $errorValidation.length) {
                const confirmationMessage = document.querySelector('.gform_confirmation_message')

                $('.accordion-form .accordion-button').click()

                setTimeout(() => {
                    if (confirmationMessage) {
                        confirmationMessage.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }

                    if (accordionForm) {
                        accordionForm.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        })
                    }
                }, 500)
            }

            const targetNodes = document.querySelectorAll('.validation_message--hidden-on-empty')

            const config = {
                childList: true,
                subtree: false
            }

            const callback = mutationsList => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        // console.log('Un élément a été ajouté ou supprimé.')
                        if (mutation.addedNodes.length > 0) {
                            // console.log('Élément(s) ajouté(s) :', mutation.addedNodes)
                            mutation.addedNodes.forEach(node => {
                                if (node.nodeType === Node.ELEMENT_NODE) {
                                    if (node.textContent.includes('Nombre de fichiers maximal atteint')) {
                                        node.classList.add('max-file-message', 'd-none')
                                    }
                                }
                            })
                        }
                        if (mutation.removedNodes.length > 0) {
                            // console.log('Élément(s) supprimé(s) :', mutation.removedNodes)
                        }
                    }
                }
            }

            targetNodes.forEach(targetNode => {
                const observer = new MutationObserver(callback)
                observer.observe(targetNode, config)
                // console.log(`Observation démarrée pour :`, targetNode)
            })
        })(window.jQuery)
    }
}
