export default listCompteRendu => {
    const $listCompteRendus = document.getElementById('list-comptes-rendus')

    console.log(document.location.search)
    if ($listCompteRendus) {
        const params = new URLSearchParams(window.location.search)

        console.log(params.getAll('thematique_compte_rendu[]').length, params.getAll('localisation[]').length)
        setTimeout(() => {
            if (params.getAll('thematique_compte_rendu[]').length || params.getAll('localisation[]').length) {
                ;($ => {
                    $('html, body').animate(
                        {
                            scrollTop: $('#list-comptes-rendus').offset().top - 200
                        },
                        200
                    )
                })(window.jQuery)
            }
        }, 3000)
    }
}
