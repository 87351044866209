import { isEmptyOrWhitespace } from '../utils/helpers'

export default {
    publicSearch: null,
    publicSearchForm: null,
    publicSearchInput: null,
    publicSearchFilters: null,
    publicSearchTimeOut: null,
    publicResultSearchFormElement: null,

    init() {
        const publicSearchElement = document.getElementById('offcanvas-search')
        const publicResultSearchFormElement = document.querySelector('.main .hero-search-form')

        if (publicSearchElement) {
            this.publicSearch = publicSearchElement
            this.triggerSearch()
        }

        if (publicResultSearchFormElement) {
            this.publicResultSearchFormElement = publicResultSearchFormElement
            this.initResultSarch()
        }
    },

    initResultSarch() {
        const publicResultSearchInput = this.publicResultSearchFormElement.querySelector('.hero-search-form-input')
        const eraseButton = this.publicResultSearchFormElement.querySelector('.hero-search-form-input + svg')
        // console.log(sage.empty_value)

        if (publicResultSearchInput.value === sage.empty_value) {
            // publicResultSearchInput.value = "";
            eraseButton.dispatchEvent(new Event('click'))
        }

        this.publicResultSearchFormElement.addEventListener('submit', e => {
            e.preventDefault()
            const searchValue = publicResultSearchInput.value

            if (isEmptyOrWhitespace(searchValue)) {
                publicResultSearchInput.focus()
            } else {
                this.publicResultSearchFormElement.submit()
            }
        })
    },

    triggerSearch() {
        this.publicSearchForm = this.publicSearch.querySelector('.hero-search-form')
        this.publicSearchInput = this.publicSearch.querySelector('.hero-search-form-input')
        this.publicSearchFilters = this.publicSearch.querySelectorAll('.hero-search-filter-cta')

        if (this.publicSearchFilters.length) {
            const searchFormAction = this.publicSearchForm.action
            this.publicSearchFilters.forEach(filter => {
                filter.addEventListener('click', () => {
                    const filterSlug = filter.dataset.slug
                    const searchValue = this.publicSearchInput.value

                    if (!isEmptyOrWhitespace(searchValue)) {
                        window.location.href = searchFormAction + '?search=' + searchValue + '&filter=' + filterSlug
                    }
                })
            })
        }

        this.publicSearch.addEventListener('shown.bs.offcanvas', () => {
            if (!this.publicSearchInput.value.trim().length) {
                this.publicSearchInput.focus()
            }
        })

        this.publicSearchForm.addEventListener('submit', e => {
            e.preventDefault()
            const searchValue = this.publicSearchInput.value

            if (isEmptyOrWhitespace(searchValue)) {
                this.publicSearchInput.focus()
            } else {
                this.publicSearchForm.submit()
                // window.location.href = this.publicSearchForm.action + '?search=' + searchValue
            }
        })
    }
}
