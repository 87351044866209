import { enableApplyFilters } from './dropdown'
import { getSelectedValues } from './helpers'
;(function ($) {
    $('.dropdown-select-component').each(function () {
        const $dropdownSelect = $(this)
        const $select = $dropdownSelect.find('.dropdown-select')
        const placeholder = $dropdownSelect.data('placeholder') ?? 'Rechercher'
        const multiple = $dropdownSelect.data('multiple') ?? true
        const action = $dropdownSelect.data('action')
        const dependsOn = $dropdownSelect.data('depends-on')
        const updateLabel = $dropdownSelect.data('update-label')
        const $labelElement = $dropdownSelect.find('.dropdown-toggle')
        const $labelElementText = $labelElement.find('.text-btn')
        const defaultLabel = $dropdownSelect
            .find('.dropdown-toggle > .text-btn')
            .contents()
            .filter(function () {
                return this.nodeType === Node.TEXT_NODE
            })
            .text()
            .trim()

        function updateSelectLabel($select) {
            const selectedText = $select
                .find('.select2-selection__choice__display')
                .map(function () {
                    return $(this).text()
                })
                .get()
                .join(', ')
            let textValue = defaultLabel
            if (selectedText.length) {
                textValue = selectedText
            }
            console.log('updateSelectLabel', textValue)
            $labelElementText
                .contents()
                .filter(function () {
                    return this.nodeType === Node.TEXT_NODE // Sélectionne uniquement le texte, pas les enfants
                })
                .first()
                .replaceWith(textValue)
            // console.log(selectedText)
            // return selectedText
        }

        $select.select2({
            placeholder: placeholder,
            multiple: multiple,
            minimumInputLength: 3,
            language: {
                inputTooShort: function () {
                    return 'Entrez au moins 3 caractères'
                }
            },
            ajax: {
                url: ajaxurl.url,
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    // console.log(dependsOn)
                    let data = {
                        action: action,
                        search: params.term
                    }
                    if (dependsOn) {
                        const pseudoApplyFilters = document.querySelector('[data-depends-on="' + dependsOn + '"]')
                        const selectedValues = getSelectedValues(pseudoApplyFilters)

                        // console.log(selectedValues)
                        if (selectedValues[dependsOn]) {
                            data['dependsOn'] = {
                                [dependsOn]: selectedValues[dependsOn]
                            }
                        }
                    }
                    console.log(data)
                    return data
                },
                processResults: function (data) {
                    addDataToObject(data)
                    return {
                        results: $.map(data, function (item) {
                            return {
                                id: item.slug,
                                text: item.name
                            }
                        })
                    }
                },
                cache: true
            }
        })

        // $select.val($dropdownSelect.data('selected')).trigger('change')
        console.log(defaultLabel)

        updateSelectLabel($dropdownSelect)

        $select.on('change', function () {
            enableApplyFilters()
            if (updateLabel) {
                updateSelectLabel($dropdownSelect)
            }
        })
    })
})(jQuery)

let storedDataBySlug = {}

// Fonction pour ajouter de nouvelles données sans doublon
function addDataToObject(newData) {
    newData.forEach(item => {
        if (!storedDataBySlug[item.slug]) {
            storedDataBySlug[item.slug] = item.name
        }
    })
}

/* function updateSelectLabel($select) {
    const selectValue = getCityNamesFromSlugs($select.val())
    let textValue = defaultLabel
    if (selectValue.length) {
        textValue = selectValue
    }
    $labelElement
        .contents()
        .filter(function () {
            return this.nodeType === Node.TEXT_NODE // Sélectionne uniquement le texte, pas les enfants
        })
        .first()
        .replaceWith(textValue)
} */
