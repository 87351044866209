import { applyFilters } from './filters'

/**
 *
 * @param $applyFilters
 * @param all boolean : if false, only visible checkbox are taken
 * @returns {{}}
 */

export function getSelectedValues($applyFilters, all = false) {
    const selectedValues = {}
    const $parent = $applyFilters.closest('.list-filters')

    ;(function ($) {
        $parent.querySelectorAll('.dropdown-component').forEach(dropdown => {
            const name = dropdown.getAttribute('data-name')
            const selectedItems = []

            if (dropdown.classList.contains('dropdown-select-component')) {
                selectedValues[name] = $('.dropdown-select', dropdown).val()
            } else if (dropdown.classList.contains('search-component')) {
                const searchValue = $('.search-component-input', dropdown).val()
                if (searchValue !== '') {
                    selectedValues[name] = searchValue
                }
                // console.log($('.search-component-input', dropdown).val(), name)
            } else if (dropdown.classList.contains('dropdown-period-component')) {
                const periodValue = $('.dropdown-period-input', dropdown).val()
                if (periodValue !== '') {
                    selectedValues[name] = periodValue
                }
            } else {
                dropdown.querySelectorAll('.dropdown-item.selected, .accordion-item.selected').forEach(item => {
                    if (all || !item.closest('li').classList.contains('d-none')) {
                        selectedItems.push(item.getAttribute('data-value'))
                    }
                })

                if (selectedItems.length > 0) {
                    selectedValues[name] = selectedItems
                }
            }
        })
    })(window.jQuery)

    return applyFilters('getSelectedValues', selectedValues)
}

export function getSelectedValue(dropdown) {
    const selectedValues = {}
    const name = dropdown.getAttribute('data-name')
    const selectedItems = []
    const all = false
    ;(function ($) {
        if (dropdown.classList.contains('dropdown-select-component')) {
            selectedValues[name] = $('.dropdown-select', dropdown).val()
        } else if (dropdown.classList.contains('search-component')) {
            const searchValue = $('.search-component-input', dropdown).val()
            if (searchValue !== '') {
                selectedValues[name] = searchValue
            }
            // console.log($('.search-component-input', dropdown).val(), name)
        } else if (dropdown.classList.contains('dropdown-period-component')) {
            const periodValue = $('.dropdown-period-input', dropdown).val()
            if (periodValue !== '') {
                selectedValues[name] = periodValue
            }
        } else {
            dropdown.querySelectorAll('.dropdown-item.selected, .accordion-item.selected').forEach(item => {
                if (all || !item.closest('li').classList.contains('d-none')) {
                    selectedItems.push(item.getAttribute('data-value'))
                }
            })

            if (selectedItems.length > 0) {
                selectedValues[name] = selectedItems
            }
        }
    })(window.jQuery)

    return selectedValues[name] || []
}

export function createUrlParams(selectedValues) {
    const params = new URLSearchParams()
    // console.log(selectedValues)

    for (const [key, values] of Object.entries(selectedValues)) {
        if (Array.isArray(values)) {
            values.forEach(value => {
                params.append(`${key}[]`, value)
            })
        } else {
            params.append(key, values)
        }
    }

    return params.toString()
}

export function getBaseUrl() {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    let pathname = url.pathname
    pathname = pathname.replace(/\/page\/\d+\/?$/, '')
    return `${url.origin}${pathname}`
}

export function getUrlPrivateSearchFilter() {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    let pathname = url.pathname
    pathname = pathname.replace(/\/page\/\d+\/?$/, '')
    let urlParams = url.searchParams
    const excludeParams = ['search_extranet', 'content_type[]', 'content_type[0]']

    for (let param of urlParams.keys()) {
        if (!excludeParams.includes(param)) {
            urlParams.delete(param)
        }
    }

    return `${url.origin}${pathname}?${urlParams.toString()}`
}

export function runFilters($applyFilters) {
    const selectedValues = getSelectedValues($applyFilters)
    console.log('runFilters', $applyFilters.getAttribute('data-is-ajax'))
    if ($applyFilters.getAttribute('data-is-ajax')) {
        if ($applyFilters.getAttribute('data-is-native-ajax')) {
            // console.log(selectedValues)
            console.log('dispatch event data-send')
            $applyFilters.dispatchEvent(new Event('data-send', { detail: selectedValues }))
        } else {
            sendSelectedValues(selectedValues, $applyFilters)
        }
    } else if ($applyFilters.getAttribute('data-is-private-search')) {
        window.location.href = getUrlPrivateSearchFilter() + '&' + createUrlParams(selectedValues)
    } else {
        window.location.href = getBaseUrl() + '?' + createUrlParams(selectedValues)
    }

    // console.log(createUrlParams(getSelectedValues($applyFilters)))
}

export function sendSelectedValues(selectedValues, $applyFilter) {
    ;(function ($) {
        // console.log(selectedValues)
        $.ajax({
            url: ajaxurl.url,
            type: 'POST',
            dataType: 'json',
            data: selectedValues,
            success: function (data) {
                console.log(data)

                $applyFilter.dispatchEvent(new Event('data-loaded', { detail: data }))
            }
        })
    })(window.jQuery)
}

export function resetFilters($resetFilters) {
    const $listFilters = $resetFilters.closest('.list-filters')
    if ($listFilters.getAttribute('data-is-ajax')) {
        $listFilters.querySelectorAll('li').forEach(li => {
            const dropdownItem = li.querySelector('.dropdown-item, .accordion-item')

            dropdownItem?.classList.remove('selected')
            dropdownItem?.classList.remove('partially-selected')

            if (!li.classList.contains('dropdown-empty')) {
                li.classList.remove('d-none')
            }
        })

        runFilters($listFilters.querySelector('.apply-filters'))
        $resetFilters.dispatchEvent(new Event('data-reset'))
    } else {
        if ($listFilters.getAttribute('data-exception-taxos')) {
            const exceptions = JSON.parse($listFilters.getAttribute('data-exception-taxos'))
            const urlParams = new URLSearchParams(window.location.search)

            urlParams.forEach((value, key) => {
                const paramBaseName = key.replace(/\[\]$/, '')

                if (!exceptions.includes(paramBaseName)) {
                    urlParams.delete(key)
                }
            })

            window.location.href = getBaseUrl() + '?' + urlParams.toString()
        } else if ($listFilters.getAttribute('data-is-private-search')) {
            window.location.href = getUrlPrivateSearchFilter()
        } else {
            window.location.href = getBaseUrl()
        }
    }
}

export function getDatesInRange(startDate, endDate, month) {
    const dates = []
    let currentDate = new Date(startDate)

    while (currentDate <= new Date(endDate)) {
        const currentMonth = currentDate.getMonth() + 1
        if (currentMonth === month) {
            const day = currentDate.getDate()
            dates.push(day)
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
}

export function getTheMonthNumber(month) {
    return parseInt(month.split('-')[1], 10)
}

export function formatFileSize(size) {
    const units = ['Bytes', 'Ko', 'Mo', 'Go', 'To']
    let unitIndex = 0
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024
        unitIndex++
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`
}

export function isEmptyOrWhitespace(str) {
    return !str || str.trim() === ''
}
