import { formatFileSize } from './helpers'

export default function () {
    ;($ => {
        $('.ginput_container_fileupload').each(function () {
            const $fileInputContainer = $(this)
            const $input = $fileInputContainer.find('input[type="file"]')
            const $fileInputSample = $('#file-upload-sample')
            const $fileInputClone = $fileInputSample.clone()
            $fileInputClone.removeClass('d-none')
            $fileInputClone.removeAttr('id')
            $fileInputClone.insertAfter($input)
            const $fileInputLabel = $fileInputClone.find('.file-upload-label')
            $input.on('change', function () {
                if (this.files && this.files.length > 0) {
                    const file = this.files[0]
                    const fileSize = file.size
                    const readableSize = formatFileSize(fileSize)
                    $fileInputLabel.text(`${file.name} (${readableSize})`)
                    $fileInputClone.addClass('not-empty')
                } else {
                    $fileInputClone.removeClass('not-empty')
                }
                const fileName = this.files[0].name
                console.log(this.files)
            })
        })
    })(window.jQuery)
}
