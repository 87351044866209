export default function (imageRefContainer) {
    const dropImages = document.querySelectorAll('.drop-img')

    function handleFile(dropZone, file, fileInput, imgPreview, isDrop) {
        imageRefContainer.ref = null

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const reader = new FileReader()
            reader.onload = function (e) {
                imgPreview.src = e.target.result
                imgPreview.parentElement.classList.remove('d-none')
                dropZone.classList.add('d-none')
                isDrop && (imageRefContainer.ref = file)
            }
            reader.readAsDataURL(file)
        } else {
            alert('Please select a valid image file.')
            fileInput.value = ''
        }
    }

    if (dropImages.length) {
        dropImages.forEach(dropImage => {
            const dropZone = dropImage.querySelector('.drop-img-zone')
            const fileInput = dropImage.querySelector('input[type=file]')
            const imageElement = dropImage.querySelector('img')
            const updateButton = dropImage.querySelector('.drop-img-modify-btn')
            const deleteButton = dropImage.querySelector('.drop-img-delete-btn')

            if (imageElement && imageElement.getAttribute('src').trim().length) {
                imageElement.parentElement.classList.remove('d-none')
                dropZone.classList.add('d-none')
            }

            dropZone.addEventListener(
                'dragover',
                e => {
                    e.preventDefault()
                },
                false
            )

            dropZone.addEventListener('dragenter', () => {
                dropZone.classList.add('drag-active')
            })

            dropZone.addEventListener('dragleave', () => {
                dropZone.classList.remove('drag-active')
            })

            dropZone.addEventListener('drop', e => {
                e.preventDefault()
                dropZone.classList.remove('drag-active')
                const file = e.dataTransfer.files[0]
                handleFile(dropZone, file, fileInput, imageElement, true)
            })

            fileInput.addEventListener('change', e => {
                const fileInputTarget = e.target

                if (fileInputTarget.files && fileInputTarget.files[0]) {
                    handleFile(dropZone, fileInputTarget.files[0], fileInputTarget, imageElement)
                }
            })

            updateButton.addEventListener('click', () => {
                fileInput.click()
            })

            deleteButton.addEventListener('click', () => {
                resetInput(dropZone, fileInput, imageElement)
            })
        })
    }
}

function resetInput(dropZone, fileInput, imgPreview) {
    fileInput.value = ''
    imgPreview.src = ''
    imgPreview.parentElement.classList.add('d-none')
    dropZone.classList.remove('d-none')
    window.removeImage = 1
}
