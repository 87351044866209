export default function lazyLoad() {
    function lazyLoadBackgrounds() {
        const lazyBackgrounds = document.querySelectorAll('.lazy-bg')
        function setBagroundImage(lazyBackground) {
            const bgDesktop = lazyBackground.getAttribute('data-bg-desktop')
            const bgTablet = lazyBackground.getAttribute('data-bg-tablet')
            const bgMobile = lazyBackground.getAttribute('data-bg-mobile')
            const screenWidth = window.innerWidth
            console.log('setbackground image')

            if (screenWidth < 768 && bgMobile) {
                lazyBackground.style.backgroundImage = 'url(' + bgMobile + ')'
            } else if (screenWidth >= 768 && screenWidth < 1024 && bgTablet) {
                lazyBackground.style.backgroundImage = 'url(' + bgTablet + ')'
            } else {
                lazyBackground.style.backgroundImage = 'url(' + bgDesktop + ')'
            }
        }

        if ('IntersectionObserver' in window) {
            let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        const lazyBackground = entry.target
                        setBagroundImage(lazyBackground)

                        lazyBackgroundObserver.unobserve(lazyBackground)
                    }
                })
            })

            lazyBackgrounds.forEach(function (lazyBackground) {
                lazyBackgroundObserver.observe(lazyBackground)
            })
        } else {
            // Fallback for browsers that do not support IntersectionObserver
            lazyBackgrounds.forEach(function (lazyBackground) {
                setBagroundImage(lazyBackground)
            })
        }
    }

    // Call lazy loading function on page load
    lazyLoadBackgrounds()

    // Optionally, update lazy loaded backgrounds on window resize
    window.addEventListener('resize', lazyLoadBackgrounds)
}
