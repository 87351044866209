import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const services = document.querySelectorAll('.js-services-block')

    if (services.length) {
        services.forEach(service => {
            const serviceSlider = new Swiper(`#${service.id} .swiper`, {
                slidesPerView: 1.15,
                slidesPerGroup: 1,
                spaceBetween: 32,
                breakpoints: {
                    480: {
                        slidesPerView: 1.25
                    },
                    640: {
                        slidesPerView: 2.15
                    },
                    992: {
                        slidesPerView: 3
                    }
                },
                navigation: {
                    nextEl: `#${service.id} .swiper-button-next`,
                    prevEl: `#${service.id} .swiper-button-prev`
                },
                pagination: {
                    el: `#${service.id} .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })
        })
    }
}
