export function readMore() {
    const messages = document.querySelectorAll('.card-message')
    console.log(messages)

    messages.forEach($message => {
        const toggle = $message.nextElementSibling
        if (toggle && toggle.classList.contains('card-message-toggle')) {
            toggle.addEventListener('click', () => {
                const isExpanded = $message.classList.toggle('expanded')
                toggle.querySelector('.card-message-toggle-label').textContent = isExpanded
                    ? 'Afficher moins'
                    : 'Lire la suite'
            })

            verifyMessage($message, toggle)

            window.addEventListener('resize', () => {
                verifyMessage($message, toggle)
            })
        }
    })
}

function verifyMessage($message, $toggle) {
    if ($message.clientHeight < 42) {
        $toggle.classList.add('d-none')
    } else {
        $toggle.classList.remove('d-none')
    }
}
