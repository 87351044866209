export default function () {
    const cartesRegionBlock = document.querySelectorAll('.js-carte-region-block')

    if (cartesRegionBlock.length) {
        cartesRegionBlock.forEach(carteRegion => {
            const regions = Array.from(carteRegion.querySelectorAll('.interactive-map .region'))
            const modals = Array.from(carteRegion.querySelectorAll('.region-presidency-modal'))
            const closeButtons = Array.from(carteRegion.querySelectorAll('.region-presidency-modal .close-btn'))
            let overlay = null

            // Fonction pour afficher la modal
            function showModal(regionId, regionElement) {
                const modal = carteRegion.querySelector(`.region-presidency-modal[data-modal-region="${regionId}"]`)
                if (!modal) return

                // Créer et ajouter un overlay si nécessaire
                if (!overlay) {
                    overlay = document.createElement('div')
                    overlay.classList.add('region-presidency-modal-overlay')
                    document.body.appendChild(overlay)
                    overlay.addEventListener('click', closeModals)
                }

                // Afficher la modal et ajouter la classe 'active' à la région cliquée
                modal.style.display = 'block'
                regionElement.classList.add('active')

                // Calcul des coordonnées de la modal par rapport à la carte
                positionModal(modal, regionElement)

                // Ajouter l'écouteur sur document pour fermer les modals en cliquant à l'extérieur
                document.addEventListener('click', outsideClickHandler)
            }

            // Fonction pour calculer et positionner la modal
            function positionModal(modal, regionElement) {
                const parentRect = carteRegion.querySelector('.interactive-map').getBoundingClientRect()
                const regionRect = regionElement.getBoundingClientRect()

                let leftPosition = regionRect.left - parentRect.left - modal.offsetWidth - 10
                const topPosition = regionRect.top - parentRect.top + regionRect.height / 2 - modal.offsetHeight / 2

                if (window.innerWidth < 900) {
                    leftPosition += 30
                }

                modal.style.position = 'absolute'
                modal.style.left = `${leftPosition}px`
                modal.style.top = `${topPosition}px`
            }

            // Fonction pour fermer toutes les modals
            function closeModals() {
                modals.forEach(modal => (modal.style.display = 'none'))
                regions.forEach(region => region.classList.remove('active'))

                // Supprimer l'overlay
                if (overlay) {
                    overlay.remove()
                    overlay = null
                }

                // Retirer l'écouteur de document
                document.removeEventListener('click', outsideClickHandler)
            }

            // Gestion du clic à l'extérieur pour fermer les modals
            function outsideClickHandler(event) {
                if (!event.target.closest('.region-presidency-modal') && !event.target.closest('.region')) {
                    closeModals()
                }
            }

            // Écouter les clics sur les régions
            carteRegion.addEventListener('click', event => {
                const region = event.target.closest('.region')
                if (region) {
                    const regionId = region.id
                    closeModals()
                    showModal(regionId, region)
                }
            })

            // Écouter les clics sur les boutons de fermeture
            closeButtons.forEach(button => {
                button.addEventListener('click', event => {
                    event.stopPropagation()
                    closeModals()
                })
            })
        })
    }
}
