export default function () {
    // Ensure dataLayer is initialized as an array if not already
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push = (function (originalPush) {
        return function () {
            const result = originalPush.apply(this, arguments)
            // Your custom logic here: Arguments contain the data being pushed
            const pushedEvent = arguments[0]

            if (pushedEvent.eventCategory && pushedEvent.eventCategory === 'Add-to-Calendar-Button') {
                if (pushedEvent.event === 'success') {
                    const eventId = pushedEvent.eventLabel.substring(9)
                    const eventElement = document.getElementById(`event-adherent-${eventId}`)

                    if (eventElement && !eventElement.querySelector('.event-adherent-subscribed')) {
                        setTimeout(() => subscribeToEvent(eventId), 2000)
                    }
                }
            }

            return result
        }
    })(window.dataLayer.push)

    function subscribeToEvent(eventId) {
        ;($ => {
            $.ajax({
                type: 'POST',
                url: ajaxurl.url,
                data: {
                    action: 'user_subscribe_event',
                    event_id: eventId
                },
                success(response) {
                    if (response.success) {
                        $('#event-adherent-' + eventId + ' .event-adherent-time').append(
                            '<div class="badge p-1 ms-0 rounded-1 text-bg-primary event-adherent-subscribed">Inscrit</div>'
                        )
                    }
                },
                error(jqXHR, textStatus, errorThrown) {
                    console.error('AJAX request failed:')
                    console.error('Status:', textStatus)
                    console.error('Error thrown:', errorThrown)
                    console.error('Response text:', jqXHR.responseText)
                }
            })
        })(window.jQuery)
    }
}
