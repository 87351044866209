export default function () {
    // Vérifie si l'élément de la section existe
    const dashboardAccesRapide = document.querySelector('.dashboard-acces-rapide')
    if (!dashboardAccesRapide) return

    // Récupérer la valeur de $counter_acces_rapide_max
    const maxAllowed = parseInt(dashboardAccesRapide.dataset.maxAllowed, 10) || 4

    // Sélectionne toutes les cases à cocher dans le menu déroulant
    const checkboxes = dashboardAccesRapide.querySelectorAll('.dropdown-menu--checkbox input[type="checkbox"]')
    const counterElement = dashboardAccesRapide.querySelector('.js-counter-acces-rapide')
    const wrapperAccesRapide = dashboardAccesRapide.querySelector('.wrapper-acces-rapide')

    // Fonction pour mettre à jour la visibilité des éléments dans .wrapper-acces-rapide
    function updateVisibility() {
        let listUrls = []
        checkboxes.forEach(checkbox => {
            const targetId = checkbox.getAttribute('data-target-acces-rapide')
            const targetElement = wrapperAccesRapide.querySelector(`[data-acces-rapide="${targetId}"]`)
            const link = targetElement.querySelector('.acces-rapide-link').getAttribute('data-unique-id')

            // console.log(link)
            if (checkbox.checked) {
                targetElement.style.display = 'block'
                listUrls.push(link)
            } else {
                targetElement.style.display = 'none'
            }
        })

        updateUserAccesRapide(listUrls)
    }

    function updateUserAccesRapide(urls) {
        console.log(urls)
        ;($ => {
            $.ajax({
                type: 'POST',
                url: ajaxurl.url,
                data: {
                    action: 'update_user_acces_rapide',
                    access_rapide_urls: urls
                },
                success(response) {
                    if (response.success) {
                        console.log('success')
                    }
                },
                failure() {
                    console.log('error')
                }
            })
        })(window.jQuery)
    }

    // Fonction pour mettre à jour le compteur de cases à cocher disponibles
    function updateCounter() {
        const checkedCount = document.querySelectorAll('.dropdown-menu--checkbox input[type="checkbox"]:checked').length
        const remaining = maxAllowed - checkedCount
        if (counterElement) {
            counterElement.textContent = remaining
        }
    }

    // Ajoute un écouteur d'événement pour gérer les changements de cases à cocher
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
            const checkedCount = document.querySelectorAll(
                '.dropdown-menu--checkbox input[type="checkbox"]:checked'
            ).length

            if (checkedCount > maxAllowed) {
                checkbox.checked = false // Décocher la case si plus de maxAllowed cases sont sélectionnées
                return
            }

            updateCounter()
            updateVisibility()
        })
    })

    // Initialisation lors du chargement de la page
    updateCounter()
    updateVisibility()
}
