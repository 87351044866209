export default {
    init() {
        ;($ => {
            const $noTypeSelectedWrapper = $('.no-type-selected')
            let values = []

            $('.liaison-type ul li a').each(function () {
                values.push($(this).data('value'))
            })

            // Supprimer les doublons
            values = [...new Set(values)]

            $('.liaison-thematique ul li').each(function () {
                let hasClass = values.some(cls => $(this).hasClass(cls))
                if (!hasClass) {
                    $(this).remove()
                }
            })

            $(`
                .no-type-selected .liaison-type ul li a, 
                .no-type-selected ~ .offcanvas #list-filters-mobile .liaison-type ul li a
                `).each(function () {
                $(this).removeClass('selected')
            })
        })(window.jQuery)
    }
}
