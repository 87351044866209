import { getSelectedValues, getSelectedValue } from './helpers'

export default function () {
    const accordionItems = document.querySelectorAll('.accordion-item')
    const accordionChecks = document.querySelectorAll('.accordion-check')
    const dropdownItems = document.querySelectorAll('.dropdown-component .dropdown-item')
    const dropdownMenus = document.querySelectorAll('.navbar-nav .dropdown')
    const applyFilters = document.querySelectorAll('.list-filters .apply-filters')
    const liaisonTypesFilters = document.querySelectorAll('.liaison-type')
    const dropdownFormsPrivate = document.querySelectorAll('.dropdown-form-private')
    const dropdownsComponent = document.querySelectorAll('.dropdown-component')

    dropdownsComponent.forEach(function (dropdownComponent) {
        const labelElement = dropdownComponent.querySelector('.dropdown-toggle')
        if (dropdownComponent.querySelector('.dropdown-item') && labelElement) {
            updateSlugsNames(jQuery(dropdownComponent))
            console.log(labelElement.innerHTML)
            dropdownComponent.setAttribute('data-default-label', labelElement.innerHTML)
            updateComponentLabel(dropdownComponent)
            /* const selectedValues = getSelectedValue(dropdownComponent)
            dropdownComponent.querySelector('.dropdown-toggle').innerHTML = getNamesFromSlugs(selectedValues) */
        }
    })

    accordionItems.forEach(function (item) {
        const collapseElement = item.querySelector('.accordion-collapse')

        if (!collapseElement) {
            return
        }

        collapseElement.addEventListener('show.bs.collapse', function (event) {
            event.stopPropagation()
            collapseElement.closest('.accordion-item').classList.add('open')
        })

        collapseElement.addEventListener('hide.bs.collapse', function (event) {
            event.stopPropagation()
            collapseElement.closest('.accordion-item').classList.remove('open')
        })
    })

    dropdownItems.forEach(function (item) {
        const $dropdownComponent = item.closest('.dropdown-component')
        const isSingle = !$dropdownComponent.getAttribute('data-is-multiple')
        // console.log(isSingle)
        const updateLabel = $dropdownComponent.getAttribute('data-update-label')
        updateParent(item)
        item.addEventListener('click', function () {
            if (isSingle) {
                item.closest('.dropdown-component')
                    .querySelector('.dropdown-item.selected')
                    ?.classList.remove('selected')
                item.classList.add('selected')
            } else {
                item.classList.toggle('selected')
            }
            /* if (updateLabel) {
                const selectedValues = getSelectedValue($dropdownComponent)
                $dropdownComponent.querySelector('.dropdown-toggle').innerHTML = getNamesFromSlugs(selectedValues)
                // item.querySelector('.dropdown-item-label').innerHTML
            } */

            updateParent(item)
            updateThematique(item)

            /* applyFilters.forEach($applyFilter => {
                $applyFilter.classList.remove('disabled')
            }) */
            enableApplyFilters()
            enableResetFilters()
            dispatchChangeEvent(item)
        })

        // console.log(updateLabel, item.classList.contains('selected'))
        /* if (updateLabel && item.classList.contains('selected')) {
            const $dropdownComponent = item.closest('.dropdown-component')
            $dropdownComponent.querySelector('.dropdown-toggle').innerHTML =
                item.querySelector('.dropdown-item-label').innerHTML

            updateSlugsNames(jQuery($dropdownComponent))
        } */
    })

    dropdownMenus.forEach(function (dropdown) {
        let dropdownToggle = dropdown.querySelector('.dropdown-toggle')

        dropdownToggle.addEventListener('focus', function () {
            dropdownInstance.show()
        })
    })

    accordionChecks.forEach(function (accordionCheck) {
        accordionCheck.addEventListener('click', function () {
            const currentAccordionItem = getClosestParent(accordionCheck, 'accordion-item')
            const isSelected =
                currentAccordionItem.classList.contains('selected') ||
                currentAccordionItem.classList.contains('partially-selected')

            updateThematique(currentAccordionItem)

            if (currentAccordionItem) {
                if (isSelected) {
                    currentAccordionItem.classList.remove('selected')
                    currentAccordionItem.classList.remove('partially-selected')
                } else {
                    currentAccordionItem.classList.add('selected')
                }

                const currentDropdownChildren = currentAccordionItem.querySelectorAll('.dropdown-item, .accordion-item')
                currentDropdownChildren.forEach(function (currentDropdownChild) {
                    if (isSelected) {
                        currentDropdownChild.classList.remove('selected')
                    } else {
                        currentDropdownChild.classList.add('selected')
                    }
                })

                updateParent(currentAccordionItem)

                enableApplyFilters()
                enableResetFilters()
            }

            dispatchChangeEvent(currentAccordionItem)
        })
    })

    liaisonTypesFilters.forEach($liaisonType => {
        const $dropdownItem = $liaisonType.querySelector('.dropdown-item')
        if ($dropdownItem) {
            updateThematique($liaisonType.querySelector('.dropdown-item'))
        }
    })

    if (dropdownFormsPrivate.length) {
        dropdownFormsPrivate.forEach(dropdown => {
            const dropdownSelectedLabel = dropdown.querySelector('.dropdown-toggle-label')
            const dropdownOptions = dropdown.querySelectorAll('input[type="checkbox"]')

            if (dropdownOptions.length) {
                if (!dropdownSelectedLabel.textContent.trim().length) {
                    updateDropdownValByCheck(dropdownOptions, dropdownSelectedLabel)
                }
                dropdownOptions.forEach(option => {
                    option.addEventListener('change', () => {
                        updateDropdownValByCheck(dropdownOptions, dropdownSelectedLabel)
                    })
                })
            }

            if (dropdown.classList.contains('mandatory')) {
                const formDropdown = dropdown.closest('form')
                const dropdownError = dropdown.querySelector('.dropdown-form-private-error')

                if (formDropdown) {
                    formDropdown.addEventListener('submit', e => {
                        if (!dropdownSelectedLabel.textContent.trim().length) {
                            e.preventDefault()

                            if (dropdownError) {
                                dropdownError.classList.remove('d-none')
                            }
                        }
                    })
                }
            }
        })
    }
}

function getClosestParent($item, className) {
    if (!$item) {
        return false
    }
    if ($item.classList && $item.classList.contains(className)) {
        return $item
    }

    if ($item.parentElement.classList.contains('dropdown-menu')) {
        return false
    }

    // console.log($item.parentElement)

    return getClosestParent($item.parentElement, className)
}

function updateParent($item) {
    // console.log($item)
    const $parentItem = getClosestParent($item, 'accordion-item')
    if (!$parentItem) {
        return
    }

    let allSelected = true
    let partiallySelected = false
    const $childrenItems = $parentItem.querySelectorAll('.dropdown-item')

    for (const $element of $childrenItems) {
        if (!$element.classList.contains('selected')) {
            allSelected = false
        } else {
            partiallySelected = true
        }
    }

    if (allSelected && partiallySelected) {
        partiallySelected = false
    }

    if (partiallySelected) {
        $parentItem.classList.add('partially-selected')
    } else {
        $parentItem.classList.remove('partially-selected')
    }

    if (allSelected) {
        $parentItem.classList.add('selected')
    } else {
        $parentItem.classList.remove('selected')
    }

    updateParent(getClosestParent($parentItem.parentElement, 'accordion-item'))
}

function dispatchChangeEvent($this) {
    ;($ => {
        const $dropdownComponent = $($this).closest('.dropdown-component')
        updateComponentLabel($dropdownComponent.get(0))

        $dropdownComponent.trigger('change')
    })(jQuery)
}

function updateThematique($target) {
    const $listFilters = $target.closest('.list-filters')
    const $typeDropdown = $target.closest('.liaison-type')
    const $thematiqueDropDown = $listFilters.querySelector('.liaison-thematique')
    const $applyFilters = $listFilters.querySelector('.apply-filters')
    if ($typeDropdown && $applyFilters && $thematiqueDropDown) {
        const typeName = $typeDropdown.getAttribute('data-name')
        const liInThematiqueDropdown = $thematiqueDropDown.querySelectorAll('li:not(.dropdown-empty)')
        const selectedValues = getSelectedValues($applyFilters)
        console.log(selectedValues)
        if (selectedValues[typeName] && selectedValues[typeName].length) {
            liInThematiqueDropdown.forEach($li => {
                const hasExceptClass = selectedValues[typeName].some(className => $li.classList.contains(className))
                if (hasExceptClass) {
                    $li.classList.remove('d-none')
                } else {
                    $li.classList.add('d-none')
                }
            })
        } else {
            liInThematiqueDropdown.forEach($li => {
                $li.classList.remove('d-none')
            })
        }

        checkIfDropdownIsEmpty($thematiqueDropDown)
    }
}

function checkIfDropdownIsEmpty($dropdown) {
    const dropdownItems = $dropdown.querySelectorAll('.dropdown-menu > li')
    const allHidden = Array.from(dropdownItems).every(li => li.classList.contains('d-none'))

    if (allHidden) {
        $dropdown.querySelector('.dropdown-menu').classList.add('dropdown-menu--empty')
    } else {
        $dropdown.querySelector('.dropdown-menu').classList.remove('dropdown-menu--empty')
    }
}

export function enableApplyFilters() {
    console.log('enableApplyFilters')
    const applyFilters = document.querySelectorAll('.list-filters .apply-filters')
    applyFilters.forEach($applyFilter => {
        $applyFilter.classList.remove('disabled')
    })
}

export function enableResetFilters() {
    console.log('enableResetFilters')
    const resetFilters = document.querySelectorAll('.list-filters .reset-filters')
    console.log(resetFilters)
    resetFilters.forEach($resetFilter => {
        console.log($resetFilter)
        $resetFilter.classList.remove('disabled')
    })
}

function updateDropdownValByCheck(checkboxes, textContainer) {
    const selectedTexts = Array.from(checkboxes)
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.nextElementSibling.textContent.trim())

    textContainer.textContent = selectedTexts.join(' / ')
}

let storedDataBySlug = {}

function updateSlugsNames($dropdownComponent) {
    ;(function ($) {
        $dropdownComponent.find('.accordion-item').each(function () {
            const slug = $(this).data('value')
            const name = $(this).find('.accordion-button').text().trim()
            storedDataBySlug[slug] = name
        })

        // Parcours des éléments .dropdown-item
        $dropdownComponent.find('.dropdown-item').each(function () {
            const slug = $(this).data('value')
            const name = $(this).find('.dropdown-item-label').text().trim()
            storedDataBySlug[slug] = name
        })

        // console.log(storedDataBySlug)
    })(window.jQuery)
}

function getNameBySlugFromObject(slug) {
    return storedDataBySlug[slug] || null
}

function getNamesFromSlugs(slugs) {
    return slugs
        .map(slug => getNameBySlugFromObject(slug))
        .filter(name => name)
        .join(', ')
}

function updateComponentLabel(dropdownComponent) {
    // dropdownComponent.querySelector('.dropdown-toggle').innerHTML = getNamesFromSlugs(selectedValues)

    const shouldUpdateLabel = dropdownComponent.getAttribute('data-update-label') === 'true'

    if (!shouldUpdateLabel) {
        return
    }
    const labelElement = dropdownComponent.querySelector('.dropdown-toggle')
    if (labelElement) {
        const selectedValues = getSelectedValue(dropdownComponent)
        if (selectedValues.length) {
            labelElement.innerHTML = `<span class="selected-label">${getNamesFromSlugs(selectedValues)}</span>`
        } else {
            labelElement.innerHTML = `<span class="default-label">${dropdownComponent.getAttribute(
                'data-default-label'
            )}</span>`
        }
    }
}
