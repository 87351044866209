export default function svgUnique() {
    document.querySelectorAll('svg').forEach((svg, index) => {
        svg.querySelectorAll('defs pattern').forEach((pattern, patternIndex) => {
            const newPatternId = `pattern_${generateUUID()}`
            const oldPatternId = pattern.id

            pattern.id = newPatternId

            // Modifier tous les attributs 'fill' qui utilisent cet ID
            svg.querySelectorAll(`[fill="url(#${oldPatternId})"]`).forEach(element => {
                element.setAttribute('fill', `url(#${newPatternId})`)
            })

            // Modifier tous les attributs 'xlink:href' qui utilisent cet ID dans le pattern
            pattern.querySelectorAll(`use[xlink\\:href="#${pattern.id}"]`).forEach(useElement => {
                useElement.setAttribute('xlink:href', `#${newPatternId}`)
            })
        })
    })
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
