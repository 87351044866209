import { getSelectedValues, runFilters, resetFilters } from '../utils/helpers'

export default {
    init() {
        if (document.querySelector('.list-filters')) {
            this.initFilters()
        }
    },

    initFilters() {
        const applyFiltersElements = document.querySelectorAll('.list-filters .apply-filters')
        const resetFiltersElements = document.querySelectorAll('.list-filters .reset-filters')

        applyFiltersElements.forEach($applyFilters => {
            $applyFilters.addEventListener('click', () => {
                runFilters($applyFilters)
            })
        })

        resetFiltersElements.forEach($resetFilters => {
            $resetFilters.addEventListener('click', event => {
                resetFilters($resetFilters)
            })
        })
    }
}
