export default function () {
    const anchorElements = document.querySelectorAll('a')

    anchorElements.forEach(function (anchor) {
        anchor.addEventListener('click', function (event) {
            if (anchor.getAttribute('href') === '#') {
                event.preventDefault()
            }
        })
    })
}
