import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const keyNumbers = document.querySelectorAll('.js-key-numbers-block')

    if (keyNumbers.length) {
        keyNumbers.forEach(keyNumber => {
            const keyNumberSlider = new Swiper(`#${keyNumber.id} .swiper`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 32,
                centerInsufficientSlides: true,
                breakpoints: {
                    640: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3
                    }
                },
                navigation: {
                    nextEl: `#${keyNumber.id} .swiper-button-next`,
                    prevEl: `#${keyNumber.id} .swiper-button-prev`
                },
                pagination: {
                    el: `#${keyNumber.id} .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })
        })
    }
}
