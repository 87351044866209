import Swiper from 'swiper'

export default function () {
    const heroFourCols = document.querySelector('.hero-four-cols')

    if (heroFourCols) {
        const heroFourColsSlider = new Swiper('.hero-four-cols .swiper', {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 32,
            centerInsufficientSlides: true,
            breakpoints: {
                640: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                },
                1280: {
                    slidesPerView: 4
                }
            }
        })
    }
}
