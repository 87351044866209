import Guide from '@scripts/guide'
import '@styles/main.scss'
import 'swiper/swiper-bundle.css'
import 'bootstrap/js/src/collapse.js'
// import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/carousel'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/offcanvas'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
// import 'bootstrap/js/src/toast'
// import 'bootstrap/js/src/tooltip'
import './utils/select'
import './utils/search-btn'

import Router from './utils/Router'
import common from './routes/common'
import modeles from './routes/modeles'

const routes = new Router({
    common,
    pageTemplateTemplateListModelesTrames: modeles
})

const { DEV } = import.meta.env

/**
 * Enable guide in dev mode
 */
DEV && new Guide().start()

document.addEventListener('DOMContentLoaded', () => {
    routes.loadEvents()
})
