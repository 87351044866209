export function notifications() {
    jQuery(document).ready(function ($) {
        $('.dropdown-notifications-item .notification-item').on('click', function (event) {
            const $notification = $(this)
            event.preventDefault()
            event.stopPropagation()
            console.log($notification.attr('data-id'))
            $notification.removeClass('notification-item-unread')

            markNotificationAsRead($(this).attr('data-id'), function (data) {
                // console.log(data)
                // console.log($notification.attr('href'))
                window.location.href = $notification.attr('href')
            })
        })

        $('.notifications-all-read').on('click', function (event) {
            event.preventDefault()
            event.stopPropagation()
            markAllNotificationsAsRead(function (data) {
                // location.reload()
                $('.notification-item').removeClass('notification-item-unread')
            })
        })

        $('.notifications-all-clear').on('click', function (event) {
            event.preventDefault()
            event.stopPropagation()
            deleteAllNotifications(function (data) {
                // location.reload()
                $('.dropdown-notifications-item').remove()
                $('.dropdown-notifications-actions').remove()
                $('.dropdown-menu li.d-none').removeClass('d-none')
            })
        })
    })
}

function markNotificationAsRead(notificationId, callback = null) {
    console.log(notificationId)
    ;($ => {
        $.ajax({
            url: ajaxurl.url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'mark_notification_as_read',
                notification_id: notificationId
            },
            success: function (data) {
                if (callback) {
                    callback(data)
                }
            }
        })
    })(window.jQuery)
}

function markAllNotificationsAsRead(callback = null) {
    ;($ => {
        $.ajax({
            url: ajaxurl.url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'mark_all_notifications_as_read'
            },
            success: function (data) {
                if (callback) {
                    callback(data)
                }
            }
        })
    })(window.jQuery)
}

function deleteAllNotifications(callback = null) {
    ;($ => {
        $.ajax({
            url: ajaxurl.url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'delete_all_notifications_for_user'
            },
            success: function (data) {
                if (callback) {
                    callback(data)
                }
            }
        })
    })(window.jQuery)
}
