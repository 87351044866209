export default function () {
    const inputSearchElements = document.querySelectorAll('input[type="search"]')

    if (inputSearchElements.length) {
        inputSearchElements.forEach(input => {
            const closeElement = input.nextElementSibling
            if (closeElement) {
                toggleClose(input, closeElement)

                input.addEventListener('input', () => {
                    toggleClose(input, closeElement)
                })

                closeElement.addEventListener('click', () => {
                    input.value = ''
                    closeElement.style.display = 'none'
                })
            }
        })
    }
}

function toggleClose(input, close) {
    if (input.value.trim().length) {
        close.style.display = 'block'
    } else {
        close.style.display = 'none'
    }
}
