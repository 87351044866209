import flatpickr from 'flatpickr'
import { enableApplyFilters } from '@scripts/utils/dropdown'

export function initDropdownPeriod() {
    const dropdownPeriodComponents = document.querySelectorAll('.dropdown-period-component')
    dropdownPeriodComponents.forEach($dropdownPeriodComponent => {
        const $dropdownPeriodInput = $dropdownPeriodComponent.querySelector('.dropdown-period-input')

        flatpickr($dropdownPeriodInput, {
            locale: {
                firstDayOfWeek: 1, // Le premier jour de la semaine est le lundi (1)
                weekdays: {
                    shorthand: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                    longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
                },
                months: {
                    shorthand: [
                        'Janv',
                        'Févr',
                        'Mars',
                        'Avr',
                        'Mai',
                        'Juin',
                        'Juil',
                        'Août',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Déc'
                    ],
                    longhand: [
                        'Janvier',
                        'Février',
                        'Mars',
                        'Avril',
                        'Mai',
                        'Juin',
                        'Juillet',
                        'Août',
                        'Septembre',
                        'Octobre',
                        'Novembre',
                        'Décembre'
                    ]
                }
            },
            mode: 'range',
            dateFormat: 'Y-m-d',
            onChange: function (selectedDates, dateStr, instance) {
                $dropdownPeriodInput.value = dateStr
                enableApplyFilters()
                // console.log(selectedDates, dateStr, instance)
            },
            inline: true,
            onMonthChange: onViewChange,
            onReady: onViewChange,
            onYearChange: onViewChange
        })
    })
}

function hideEmptyWeeks(selectedDates, dateStr, instance) {
    // Obtenir tous les jours du calendrier
    const days = instance.calendarContainer.querySelectorAll('.flatpickr-day')
    console.log('hideEmptyWeeks')

    // Parcourir les semaines dans le calendrier
    let currentWeek = []
    days.forEach((day, index) => {
        currentWeek.push(day)

        // Chaque 7 jours, vérifier si toute la semaine est hors du mois
        if ((index + 1) % 7 === 0) {
            const allDaysOutOfMonth = currentWeek.every(
                day => day.classList.contains('nextMonthDay') || day.classList.contains('prevMonthDay')
            )
            if (allDaysOutOfMonth) {
                // Masquer toute la semaine si elle ne contient aucun jour du mois
                currentWeek.forEach(day => (day.style.display = 'none'))
            } else {
                currentWeek.forEach(day => (day.style.display = ''))
            }
            currentWeek = []
        }
    })
}

/* function customizeYearSelect(selectedDates, dateStr, instance) {
    // Obtenir l'élément input de l'année dans Flatpickr
    const yearInput = instance.calendarContainer.querySelector('.numInput.cur-year')
    if (!yearInput) return

    // Remplacer l'input par un select si ce n'est pas déjà fait
    if (yearInput.tagName === 'INPUT') {
        const currentYear = parseInt(yearInput.value)

        // Créer un select pour l'année
        const selectYear = document.createElement('select')
        selectYear.classList.add('flatpickr-year-select')

        // Ajouter des options de quelques années (par exemple, de 1900 à 2100)
        for (let year = 1900; year <= 2100; year++) {
            const option = document.createElement('option')
            option.value = year
            option.text = year
            if (year === currentYear) {
                option.selected = true
            }
            selectYear.appendChild(option)
        }

        // Remplacer l'input par le select
        yearInput.replaceWith(selectYear)

        // Gérer le changement d'année
        selectYear.addEventListener('change', function () {
            instance.changeYear(parseInt(selectYear.value))
        })
    }
} */

function customizeYearSelect(selectedDates, dateStr, instance) {
    // Obtenir l'élément input de l'année dans Flatpickr
    const yearInput = instance.calendarContainer.querySelector('.numInput.cur-year')
    if (!yearInput) return

    // Remplacer l'input par un select si ce n'est pas déjà fait
    if (yearInput.tagName === 'INPUT') {
        const currentYear = new Date().getFullYear()

        // Créer un select pour l'année
        const selectYear = document.createElement('select')
        selectYear.classList.add('flatpickr-year-select')

        // Définir la plage de deux ans avant l'année courante à sept ans après
        const startYear = currentYear - 2
        const endYear = currentYear + 7

        // Ajouter des options d'années pour cette plage
        for (let year = startYear; year <= endYear; year++) {
            const option = document.createElement('option')
            option.value = year
            option.text = year
            if (year === currentYear) {
                option.selected = true
            }
            selectYear.appendChild(option)
        }

        // Remplacer l'input par le select
        yearInput.replaceWith(selectYear)

        // Gérer le changement d'année
        selectYear.addEventListener('change', function () {
            instance.changeYear(parseInt(selectYear.value))
        })
    }
}

function onViewChange(selectedDates, dateStr, instance) {
    console.log('onViewChange')
    customizeYearSelect(selectedDates, dateStr, instance)
    hideEmptyWeeks(selectedDates, dateStr, instance)
}
