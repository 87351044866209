import { enableApplyFilters } from './dropdown'
import { runFilters } from './helpers'
;(function ($) {
    const $searchComponents = document.querySelectorAll('.search-component')
    $searchComponents.forEach(searchComponent => {
        const $searchComponent = $(searchComponent)
        const $searchBtn = $searchComponent.find('.search-component-btn')
        const $searchInput = $searchComponent.find('.search-component-input')
        const $applyFilters = searchComponent.closest('.list-filters').querySelector('.apply-filters')

        $searchBtn.on('click', function () {
            $searchInput.focus()
            $searchComponent.addClass('search-component--active')
        })

        if ($searchInput.val() !== '') {
            $searchComponent.addClass('search-component--active')
        }

        $(document).on('click', function (e) {
            if (!$(e.target).closest('.search-component').length && $searchInput.val() === '') {
                $searchComponent.removeClass('search-component--active')
            }
        })

        $searchInput.on('change', function () {
            enableApplyFilters()
        })

        $searchInput.on('keydown', function (event) {
            if (event.key === 'Enter') {
                runFilters($applyFilters)
            } else if (event.key === 'Escape') {
                $searchInput.blur()
            }
        })
    })
})(jQuery)
