import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const dashboardDernieresActualites = document.querySelectorAll('.dernieres-actualites-swiper')
    if (dashboardDernieresActualites.length) {
        dashboardDernieresActualites.forEach(actuality => {
            const isDossier = !!actuality.closest('.is-dossier')
            // console.log(isDossier)
            const swiper = actuality.querySelector('.swiper')
            const nextBtn = actuality.querySelector('.swiper-button-next')
            const prevBtn = actuality.querySelector('.swiper-button-prev')
            const pagination = actuality.querySelector('.swiper-pagination')
            const dernieresActualitiesSlider = new Swiper(swiper, {
                slidesPerView: 1.15,
                slidesPerGroup: 1,
                spaceBetween: 32,
                breakpoints: {
                    480: {
                        slidesPerView: 1.25
                    },
                    640: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    992: {
                        slidesPerView: isDossier ? 2 : 3,
                        slidesPerGroup: isDossier ? 2 : 3
                    },
                    1280: {
                        slidesPerView: isDossier ? 2 : 4,
                        slidesPerGroup: isDossier ? 2 : 4
                    }
                },
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn
                },
                pagination: {
                    el: pagination,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })
        })
    }
}
