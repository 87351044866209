export default function (formSelector, inputPass1Id, inputPass2Id, inputPassWrapperSelector) {
    const form = document.querySelector(formSelector)

    // Ajout de l'événement 'keyup' pour valider les critères à chaque saisie de l'utilisateur
    if (form) {
        const pass1 = document.getElementById(inputPass1Id)
        const pass2 = document.getElementById(inputPass2Id)
        const submitButton = form.querySelector('button[type="submit"]')

        const lengthRule = document.getElementById('length')
        const lowercaseRule = document.getElementById('lowercase')
        const uppercaseRule = document.getElementById('uppercase')
        const numberSpecialRule = document.getElementById('number-special')
        const matchRule = document.getElementById('match')
        const rulesWrapper = document.querySelector('.js-control-password')

        // Initialisation du bouton en mode désactivé
        submitButton.disabled = true
        pass1.addEventListener('keyup', validatePassword)
        pass2.addEventListener('keyup', validatePassword)

        function validatePassword() {
            const pass1Value = pass1.value
            const pass2Value = pass2.value

            // Suppression des anciens messages d'erreur
            let errorDiv = form.querySelector('.form-error')
            if (errorDiv) {
                errorDiv.remove()
            }

            if (pass1Value.trim().length || pass2Value.trim().length) {
                rulesWrapper.classList.remove('d-none')
            } else {
                rulesWrapper.classList.add('d-none')
            }

            // Validation des critères du mot de passe
            const lengthValid = pass1Value.length >= 8
            const lowercaseValid = /[a-z]/.test(pass1Value)
            const uppercaseValid = /[A-Z]/.test(pass1Value)
            const numberSpecialValid = /\d|[!@#$%^&*(),.?":{}|<>]/.test(pass1Value)
            const passwordsMatch = pass1Value === pass2Value && pass1Value.length > 0

            // Mise à jour de l'état visuel des règles
            updateRuleStatus(lengthRule, lengthValid)
            updateRuleStatus(lowercaseRule, lowercaseValid)
            updateRuleStatus(uppercaseRule, uppercaseValid)
            updateRuleStatus(numberSpecialRule, numberSpecialValid)
            updateRuleStatus(matchRule, passwordsMatch)

            // Activer/Désactiver le bouton de soumission si tous les critères sont valides
            submitButton.disabled = !(
                lengthValid &&
                lowercaseValid &&
                uppercaseValid &&
                numberSpecialValid &&
                passwordsMatch
            )
        }

        function updateRuleStatus(ruleElement, isValid) {
            if (isValid) {
                ruleElement.classList.remove('invalid')
                ruleElement.classList.add('valid')
            } else {
                ruleElement.classList.remove('valid')
                ruleElement.classList.add('invalid')
            }
        }

        // Gestion de la soumission du formulaire
        form.addEventListener('submit', event => {
            if (submitButton.disabled) {
                event.preventDefault()

                let errorMessages = ['Veuillez corriger les erreurs dans le mot de passe avant de soumettre.']

                let errorDiv = document.createElement('div')
                errorDiv.classList.add('form-error')
                errorMessages.forEach(message => {
                    let errorMessage = document.createElement('p')
                    errorMessage.textContent = message
                    errorDiv.appendChild(errorMessage)
                })

                submitButton.insertAdjacentElement('beforebegin', errorDiv)
            }
        })

        // Ajouter un bouton pour afficher/masquer les mots de passe
        addPasswordToggle(pass1, 'toggle-pass1')
        addPasswordToggle(pass2, 'toggle-pass2')
    }

    // Fonction pour ajouter un bouton pour afficher/masquer le mot de passe
    function addPasswordToggle(passwordField, toggleClassName) {
        const pwdWrap = passwordField.closest(inputPassWrapperSelector)
        if (pwdWrap) {
            const toggleBtn = document.createElement('button')
            toggleBtn.type = 'button'
            toggleBtn.textContent = 'Show'
            toggleBtn.className = toggleClassName

            // Ajouter le bouton après le champ de mot de passe
            pwdWrap.appendChild(toggleBtn)

            // Fonction pour basculer la visibilité du mot de passe
            toggleBtn.addEventListener('click', function () {
                if (passwordField.type === 'password') {
                    passwordField.type = 'text'
                    toggleBtn.textContent = 'Hide'
                    toggleBtn.classList.add('show')
                } else {
                    passwordField.type = 'password'
                    toggleBtn.textContent = 'Show'
                    toggleBtn.classList.remove('show')
                }
            })
        }
    }
}
