export default function () {
    const lightboxElements = document.querySelectorAll('.modal.lightbox')

    if (lightboxElements.length) {
        lightboxElements.forEach(lightbox => {
            const youtubeIframe = lightbox.querySelector('.youtube-video')

            if (youtubeIframe) {
                lightbox.addEventListener('hidden.bs.modal', function () {
                    const src = youtubeIframe.src
                    youtubeIframe.src = ''
                    youtubeIframe.src = src
                })
            }
        })
    }
}
