import svgUnique from '../utils/svg-unique'
import scroll from '../utils/scroll'
import lazyLoad from '../utils/lazyLoad'
import dropdown from '../utils/dropdown'
import tools from '../utils/tools'
import blog from './blog'
import services from '../blocks/services'
import temoignages from '../blocks/temoignages'
import textMedia from '../blocks/textMedia'
import maillageActualites from '../partials/maillage-actualites'
import chiffresCles from '../blocks/chiffresCles'
import animate from '../utils/animate'
import heroFourCols from '../partials/heroFourCols'
import citation from '../blocks/citation'
import photoVideo from '../blocks/photoVideo'
import lightbox from '../utils/lightbox'
import actualites from '../blocks/actualites'
import formBlock from '../blocks/formBlock'
import carteRegion from '../blocks/carteRegion'
import tmlLogin from '../components/connexion/tmlLogin'
import formOnboarding from '../components/connexion/formOnboarding'
import formResetPassword from '../components/connexion/formResetPassword'
import dashboardAccesRapide from '../partials/dashboard-acces-rapide'
import tinyMce from '../utils/tinyMce'
import dropImage from '../utils/dropImage'
import { Menu } from '../components/menu'
import partenairesCarousel from '../components/partenairesCarousel'
import dernieresActualitesSwiper from '../components/dernieres-actualites-swiper'
import formChangePassword from '../components/connexion/formChangePassword'
import addToCalendarButton from '../utils/addToCalendarButton'
import calendarEvents from '../utils/calendarEvents'
import { initDropdownPeriod } from '../components/dropdown-period'
import inputSearch from '../utils/inputSearch'
import privateSearch from './privateSearch'
import eventsCarousel from '../components/eventsCarousel'
import adherentsCarousel from '../components/adherentsCarousel'
import publicSearch from './publicSearch'
import listComptesRendus from '../partials/list-comptes-rendus'
import fileUpload from '../utils/fileUpload'
import { applicationForm } from '../utils/applicationForm'
import { offreForm } from '../utils/offreForm'
import { favoris } from '../components/favoris'
import { notifications } from '../components/notifications'
import { readMore } from '../utils/read-more'

export default {
    init() {
        const calendarAjaxElement = document.querySelector('.js-ajax-load-calendar')
        const imgFileUpload = { ref: null }

        tools()
        svgUnique()
        scroll()
        lazyLoad()
        dropdown()
        blog.init()
        services()
        temoignages()
        textMedia()
        maillageActualites()
        chiffresCles()
        animate()
        heroFourCols()
        citation()
        photoVideo()
        lightbox()
        actualites()
        formBlock()
        carteRegion()
        tmlLogin()
        formOnboarding()
        formResetPassword()
        dashboardAccesRapide()
        console.log(calendarAjaxElement)
        partenairesCarousel()
        adherentsCarousel()
        initDropdownPeriod()
        dernieresActualitesSwiper()
        eventsCarousel()
        dropImage(imgFileUpload)
        Menu()
        formChangePassword()
        addToCalendarButton()
        listComptesRendus()
        fileUpload()
        applicationForm()
        offreForm(imgFileUpload)
        favoris()
        notifications()
        readMore()
        if (calendarAjaxElement) {
            calendarEvents(calendarAjaxElement)
        }
        inputSearch()
        publicSearch.init()
        privateSearch.init()
        // tinyMce()
    }
}
