export default initScroll => {
    addScrollListener()
    handleAnchorScroll()
    handleFormPrivateScroll()

    // Scroll to anchor on link click
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault()
            const targetId = this.getAttribute('href')
            if (targetId === '#') {
                return
            }
            const targetElement = document.querySelector(targetId)
            if (targetElement) {
                scrollToElement(targetElement)
                window.history.pushState(null, null, targetId)
            }
        })
    })
}

function addScrollListener() {
    window.addEventListener('scroll', event => {
        if (window.scrollY > 0) {
            document.body.classList.add('scrolled')

            if (window.scrollY > 49) {
                document.body.classList.add('nav-top-fixed')
            } else {
                document.body.classList.remove('nav-top-fixed')
            }
        } else {
            document.body.classList.remove('scrolled')
            document.body.classList.remove('nav-top-fixed')
        }
    })
}

function scrollToElement(element) {
    const navMobileHeight = document.querySelector('nav.nav-mobile')?.offsetHeight ?? 0
    const navDesktopHeight = document.querySelector('nav.primary-navigation')?.offsetHeight ?? 0
    let navPrivateHeight = document.querySelector('header.header-private')?.offsetHeight ?? 0

    if (window.matchMedia('(min-width: 1280px)').matches) {
        navPrivateHeight = 0
    }

    const navHeight = navMobileHeight + navDesktopHeight + navPrivateHeight
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - navHeight
    window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
    })
}

function handleAnchorScroll() {
    const hash = window.location.hash
    if (hash) {
        const element = document.querySelector(hash)
        if (element) {
            scrollToElement(element)
        }
    }
}

function handleFormPrivateScroll() {
    const formSuccess = document.querySelector('.form-private-success-message')

    if (formSuccess) {
        const formSection = formSuccess.closest('.form-private-block')

        if (formSection) {
            scrollToElement(formSection)
        }
    }
}
