import { createUrlParams, getSelectedValues } from '../utils/helpers'

export default {
    privateSearch: null,
    privateSearchForm: null,
    privateSearchSubmit: null,
    privateSearchButton: null,
    privateSearchInput: null,
    privateResultSearchFormElement: null,

    init() {
        const privateSearchElement = document.getElementById('modal-private-search')
        const privateResultSearchFormElement = document.querySelector('#app .private-search-form')
        console.log(privateResultSearchFormElement)

        if (privateSearchElement) {
            this.privateSearch = privateSearchElement
            this.triggerSearch()
        }

        if (privateResultSearchFormElement) {
            this.privateResultSearchFormElement = privateResultSearchFormElement
            this.initResultSarch()
        }
    },

    triggerSearch() {
        this.privateSearchForm = this.privateSearch.querySelector('.private-search-form')
        this.privateSearchInput = this.privateSearch.querySelector('.private-search-form-input')
        this.privateSearchSubmit = this.privateSearch.querySelector('.private-search-form-submit')

        this.privateSearch.addEventListener('shown.bs.modal', () => {
            if (!this.privateSearchInput.value.trim().length) {
                this.privateSearchInput.focus()
            }
        })

        this.privateSearchForm.addEventListener('submit', e => {
            e.preventDefault()
            this.getSearchParams()
        })
    },

    getSearchParams() {
        let selectedValues = getSelectedValues(this.privateSearchSubmit)
        const inputSearchVal = this.privateSearchForm.querySelector('input[type="search"]').value

        if (inputSearchVal.trim().length) {
            const searchTerms = Object.assign({ search_extranet: inputSearchVal }, selectedValues)
            selectedValues = { ...searchTerms }
            window.location.href = this.privateSearchForm.action + '?' + createUrlParams(selectedValues)
        }
    },

    initResultSarch() {
        const privateResultSearchInput = this.privateResultSearchFormElement.querySelector('.private-search-form-input')
        const eraseButton = this.privateResultSearchFormElement.querySelector('.private-search-form-input + svg')

        if (privateResultSearchInput.value === sage.empty_value) {
            eraseButton.dispatchEvent(new Event('click'))
        }

        this.privateResultSearchFormElement.addEventListener('submit', e => {
            e.preventDefault()
            const searchValue = privateResultSearchInput.value

            if (isEmptyOrWhitespace(searchValue)) {
                privateResultSearchInput.focus()
            } else {
                this.privateResultSearchFormElement.submit()
            }
        })
    }
}
