// filters.js

// Objet pour stocker les filtres
const filters = {}

// Fonction pour ajouter un filtre
function addFilter(filterName, callback) {
    if (!filters[filterName]) {
        filters[filterName] = []
    }
    filters[filterName].push(callback)
}

// Fonction pour appliquer un filtre
function applyFilters(filterName, value, ...args) {
    if (filters[filterName]) {
        filters[filterName].forEach(function (callback) {
            value = callback(value, ...args)
        })
    }
    return value
}

// Export des fonctions
export { addFilter, applyFilters }
