export function favoris() {
    jQuery(document).ready(function ($) {
        // $('.favoris-btn').on('click', function () {

        $('#app').on('click', '.actualite-card-favoris', function (e) {
            e.stopPropagation()
            e.preventDefault()
        })

        $('#app').on('click', '.favoris-btn', function (e) {
            const $button = $(this)
            const postId = $button.data('post')
            const isFavori = $button.data('is-favori') === 1
            $button.toggleClass('is-favoris')

            $.ajax({
                url: ajaxurl.url,
                method: 'POST',
                data: {
                    action: 'toggle_favoris',
                    post_id: $button.data('post')
                },
                success: function (response) {
                    if (response.success) {
                        /* $button.data('is-favori', isFavori ? 0 : 1);
                        $button.text(isFavori ? 'Ajouter aux favoris' : 'Retirer des favoris');
                        
                        if (!isFavori && response.data.date) {
                            console.log(`Ajouté aux favoris le : ${response.data.date}`);
                        } */
                        console.log($button, response.data)
                        if (response.data.is_favoris) {
                            $button.addClass('is-favoris')
                        } else {
                            $button.removeClass('is-favoris')
                        }
                        console.log(response)
                    } else {
                        alert(response.data.message)
                    }
                },
                error: function () {
                    alert('Une erreur est survenue.')
                }
            })
        })
    })
}

export function updateFavorisId($wrapper, $id) {
    jQuery(document).ready(function ($) {
        $($wrapper).data('post', $id)
        console.log($($wrapper).data('post'))

        $.ajax({
            url: ajaxurl.url,
            method: 'POST',
            data: {
                action: 'is_favorite',
                post_id: $id
            },
            success: function (response) {
                console.log(response)
                if (response.data.is_favoris) {
                    $($wrapper).find('.favoris-btn').addClass('is-favoris')
                } else {
                    $($wrapper).find('.favoris-btn').removeClass('is-favoris')
                }
            },
            error: function () {
                alert('Une erreur est survenue.')
            }
        })
    })
}
