import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default function () {
    const actualities = document.querySelectorAll('.block-actualities')

    if (actualities.length) {
        actualities.forEach(actuality => {
            const actualitiesSlider = new Swiper(`#${actuality.id} .swiper`, {
                slidesPerView: 1.15,
                slidesPerGroup: 1,
                spaceBetween: 32,
                breakpoints: {
                    480: {
                        slidesPerView: 1.25
                    },
                    640: {
                        slidesPerView: 2.15
                    },
                    992: {
                        slidesPerView: 3
                    },
                    1280: {
                        slidesPerView: 4
                    }
                },
                navigation: {
                    nextEl: `#${actuality.id} .swiper-button-next`,
                    prevEl: `#${actuality.id} .swiper-button-prev`
                },
                pagination: {
                    el: `#${actuality.id} .swiper-pagination`,
                    type: 'bullets',
                    clickable: true
                },
                modules: [Navigation, Pagination]
            })
        })
    }
}
