import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default initMaillageActualites => {
    const $maillages = document.querySelectorAll('.maillage-actualites')

    $maillages.forEach(maillage => {
        const perView = maillage.getAttribute('data-per-view') ? maillage.getAttribute('data-per-view') : 4
        const swiperContainer = maillage.querySelector('.swiper')

        new Swiper(swiperContainer, {
            slidesPerView: 1.15,
            spaceBetween: 32,
            centeredSlides: false,
            centerInsufficientSlides: true,
            breakpoints: {
                480: {
                    slidesPerView: 1.25
                },
                640: {
                    slidesPerView: 2.15
                },
                992: {
                    slidesPerView: 3.15
                },
                1024: {
                    slidesPerView: perView
                }
            },

            pagination: {
                el: swiperContainer.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            },
            modules: [Navigation, Pagination],

            // Navigation arrows
            navigation: {
                nextEl: swiperContainer.querySelector('.swiper-button-next'),
                prevEl: swiperContainer.querySelector('.swiper-button-prev')
            }
        })
    })
}
