import calendar from './calendar'

export default function (calendarElement) {
    if (calendarElement.id === 'calendrier-agenda') {
        calendar('filter_evenement_subscribed_calendar_list')
        console.log('filter_evenement_subscribed_calendar_list')
    } else {
        console.log('filter_evenement_calendar_list')
        calendar('filter_evenement_calendar_list')
    }
}
