import { getSelectedValue, getSelectedValues } from '../utils/helpers'
export function offreForm(imageRefContainer) {
    ;($ => {
        const $formWrapper = $('#edition-offre')
        const $form = $formWrapper.find('form')

        if ($formWrapper) {
            const $regionInput = $formWrapper.find('.dropdown-component[data-name="localisation"]')
            const $villeInput = $formWrapper.find('.dropdown-component[data-name="ville"]')
            // console.log($regionInput)
            $regionInput.on('change', function () {
                // console.log(this)
                $villeInput.find('.dropdown-select').val(null).trigger('change')
            })
        }

        $form.on('submit', function (e) {
            e.preventDefault()
            $('.principal-validation-message').addClass('d-none')
            // console.log(validateForm($form))
            // console.log(errorList)
            if (validateForm($form)) {
                // if (true) {
                /* console.log($form.serialize())
                console.log(
                    getSelectedValues(document.getElementById('edition-offre').querySelector('.dropdown-component'))
                ) */
                $formWrapper.find('.spinner-border').removeClass('d-none')
                const customData = getSelectedValues(
                    document.getElementById('edition-offre').querySelector('.dropdown-component')
                )

                console.log(customData)

                let form = document.getElementById('edition-offre-form')
                let formData = new FormData()

                // Parcourir tous les éléments du formulaire
                form.querySelectorAll('input, textarea').forEach(input => {
                    if (input.type === 'file') {
                        console.log(input.type)
                        // Si c'est un fichier, ajoutez le fichier sélectionné
                        if (input.files.length > 0) {
                            console.log(input.files)
                            formData.append(input.name, input.files[0]) // Ajoute le fichier
                        } else {
                            if (imageRefContainer.ref) {
                                formData.append(input.name, imageRefContainer.ref)
                            }
                        }
                    } else {
                        // Pour tous les autres champs
                        formData.append(input.name, input.value)
                    }
                })

                formData.append('action', 'update_offre')

                for (let k in customData) {
                    if (customData.hasOwnProperty(k)) {
                        formData.append(k, customData[k])
                    }
                }

                // console.log(formData.entries())
                for (let [key, value] of formData.entries()) {
                    console.log(key + ': ' + value)
                }

                if (!formData.has('offre_company_logo')) {
                    if (window.hasLogo == '1' && window.removeImage == 1) {
                        formData.append('company_logo_off', 1)
                    }
                }

                $.ajax({
                    type: 'POST',
                    url: ajaxurl.url,
                    data: formData,
                    processData: false,
                    contentType: false,
                    success(response) {
                        if (response.success) {
                            console.log('success')
                            $formWrapper.addClass('success')
                            const url = new URL(window.location.href)
                            url.searchParams.set('offre_id', response.data.offre_id)
                            url.searchParams.set('success', true)
                            window.location.href = url.toString()
                        }
                    },
                    failure() {
                        console.log('error')
                    }
                })
            } else {
                $('.principal-validation-message').removeClass('d-none')
                $('.text-success').addClass('d-none')
            }
        })
    })(jQuery)
}

let errorList = []
const errorMessagesList = {
    required: {
        name: 'required',
        message: 'Ce champ est nécessaire.',
        class: 'input-wrapper-required'
    },
    email: {
        name: 'email',
        message: 'Veuillez saisir une adresse email valide.',
        class: 'input-wrapper-is-email'
    }
}
function validateForm($form) {
    let isValid = true
    errorList = []
    ;($ => {
        $form.find('.input-wrapper').each(function () {
            const $currentWrapper = $(this)
            let currentIsValid = true
            $currentWrapper.removeClass('input-wrapper-error')
            if ($currentWrapper.hasClass('input-wrapper-required')) {
                if ($currentWrapper.find('.form-private-input').length) {
                    if (!$currentWrapper.find('.form-private-input').val()) {
                        isValid = false
                        currentIsValid = false
                    }
                }

                if ($currentWrapper.find('.dropdown-component').length) {
                    if (getSelectedValue($currentWrapper.find('.dropdown-component')[0]).length === 0) {
                        isValid = false
                        currentIsValid = false
                    }
                    console.log(
                        getSelectedValue($currentWrapper.find('.dropdown-component')[0]),
                        getSelectedValue($currentWrapper.find('.dropdown-component')[0]).length,
                        getSelectedValue($currentWrapper.find('.dropdown-component')[0]).length === 0,
                        currentIsValid
                    )
                }

                if (!currentIsValid) {
                    $currentWrapper.addClass('input-wrapper-error')
                    addErrorToList($currentWrapper, errorMessagesList.required)
                }
            }

            /**
             * à continuer ici pour les autres validations
             */
            /* if ($currentWrapper.hasClass('input-wrapper-is-email')) {
                // vérification si c'est un email
            } */
        })

        $('.validation-message').text('')
        $('.validation-message').addClass('d-none')
        errorList.forEach(error => {
            const $wrapper = error.wrapper
            $wrapper.find('.validation-message').append(`<p>${error.type.message}</p>`)
            $wrapper.find('.validation-message').removeClass('d-none')
        })
    })(jQuery)
    return isValid
}

function addErrorToList($wrapper, type) {
    errorList.push({
        wrapper: $wrapper,
        type: type
    })
}
